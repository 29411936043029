<template>
  <div class="l-wrapper loggedin">
    <AppHeader pageTitle="利用規約" />
    <AppSideMenu />
    <main class="l-main">
      <div class="l-container">
        <div class="l-contents l-box">
          <div class="l-box title-wrap">
            <h3 class="c-lead">利用規約の見出しが入ります</h3>
          </div>
          <div>
            <div class="c-text sub-title">
              <h4>利用規約の見出しが入ります</h4>
            </div>
            <div>
              <h5 class="c-lead-xs-black l-item">利用規約の見出しが入ります</h5>
              <p class="l-item">
                利用規約の本文が入ります利用規約の本文が入ります利用規約の本文が入ります
                利用規約の本文が入ります利用規約の本文が入ります利用規約の本文が入ります
                利用規約の本文が入ります利用規約の本文が入ります利用規約の本文が入ります
                利用規約の本文が入ります利用規約の本文が入ります利用規約の本文が入ります
                利用規約の本文が入ります利用規約の本文が入ります利用規約の本文が入ります
                利用規約の本文が入ります利用規約の本文が入ります利用規約の本文が入ります
                <a class="c-text text-link">本文にリンクが入る場合</a>
                利用規約の本文が入ります利用規約の本文が入ります利用規約の本文が入ります
              </p>
              <ul class="c-list num l-item">
                <li v-for="num in 3" :key="`sample-list01-${num}`">
                  利用規約の本文が入ります利用規約の本文が入ります利用規約の本文が入ります利用規約の本文が入ります利用規約の本文が入ります利用規約の本文が入ります利用規約の本文が入ります利用規約の本文が入ります
                </li>
              </ul>
              <p>
                利用規約の本文が入ります利用規約の本文が入ります利用規約の本文が入ります
                利用規約の本文が入ります利用規約の本文が入ります利用規約の本文が入ります
                利用規約の本文が入ります利用規約の本文が入ります利用規約の本文が入ります
              </p>
              <p class="l-item">
                <span class="u-txt-red">※</span>注記が入ります
              </p>
              <ul class="c-list disc l-item">
                <li v-for="num in 3" :key="`sample-list02-${num}`">箇条書き</li>
              </ul>
              <p class="c-text indent">
                <span class="u-txt-red">※</span>1
                注記文が入ります注記文が入ります注記文が入ります注記文が入ります注記文が入ります。注記文が入ります注記文が入ります注記文が入ります注記文が入ります注記文が入ります。
              </p>
              <p class="c-text indent">
                <span class="u-txt-red">※</span>2
                注記文が入ります注記文が入ります注記文が入ります注記文が入ります注記文が入ります。
              </p>
            </div>
          </div>
        </div>

        <div class="l-contents l-box">
          <div class="l-box title-wrap">
            <h3 class="c-lead">
              利用規約の見出しが入ります利用規約の見出しが入ります利用規約の見出しが入ります利用規約の見出しが入ります利用規約の見出しが入ります利用規約の見出しが入ります
            </h3>
          </div>
          <div>
            <div class="c-text sub-title">
              <h4>
                利用規約の見出しが入ります利用規約の見出しが入ります利用規約の見出しが入ります利用規約の見出しが入ります利用規約の見出しが入ります利用規約の見出しが入ります
              </h4>
            </div>
            <div class="l-area">
              <h5 class="c-lead-xs-black l-item">
                利用規約の見出しが入ります利用規約の見出しが入ります利用規約の見出しが入ります利用規約の見出しが入ります利用規約の見出しが入ります
              </h5>
              <p class="l-item">
                利用規約の本文が入ります利用規約の本文が入ります利用規約の本文が入ります
                利用規約の本文が入ります利用規約の本文が入ります利用規約の本文が入ります
                利用規約の本文が入ります利用規約の本文が入ります利用規約の本文が入ります
                利用規約の本文が入ります利用規約の本文が入ります利用規約の本文が入ります
                利用規約の本文が入ります利用規約の本文が入ります利用規約の本文が入ります
                利用規約の本文が入ります利用規約の本文が入ります利用規約の本文が入ります
                <a class="c-text text-link">本文にリンクが入る場合</a>
                利用規約の本文が入ります利用規約の本文が入ります利用規約の本文が入ります
              </p>
            </div>
            <div>
              <h5 class="c-lead-xs-black l-item">
                利用規約の見出しが入ります利用規約の見出しが入ります利用規約の見出しが入ります利用規約の見出しが入ります利用規約の見出しが入ります
              </h5>
              <p class="l-item">
                利用規約の本文が入ります利用規約の本文が入ります利用規約の本文が入ります
                利用規約の本文が入ります利用規約の本文が入ります利用規約の本文が入ります
                利用規約の本文が入ります利用規約の本文が入ります利用規約の本文が入ります
                利用規約の本文が入ります利用規約の本文が入ります利用規約の本文が入ります
                利用規約の本文が入ります利用規約の本文が入ります利用規約の本文が入ります
                利用規約の本文が入ります利用規約の本文が入ります利用規約の本文が入ります
                <a class="c-text text-link">本文にリンクが入る場合</a>
                利用規約の本文が入ります利用規約の本文が入ります利用規約の本文が入ります
              </p>
            </div>
          </div>
          <div>
            <div class="c-text sub-title">
              <h4>利用規約の見出しが入ります利用規約の見出しが入ります</h4>
            </div>
            <div>
              <p class="l-item">
                利用規約の本文が入ります利用規約の本文が入ります利用規約の本文が入ります
                利用規約の本文が入ります利用規約の本文が入ります利用規約の本文が入ります
                利用規約の本文が入ります利用規約の本文が入ります利用規約の本文が入ります
                利用規約の本文が入ります利用規約の本文が入ります利用規約の本文が入ります
                利用規約の本文が入ります利用規約の本文が入ります利用規約の本文が入ります
                利用規約の本文が入ります利用規約の本文が入ります利用規約の本文が入ります
                <a class="c-text text-link">本文にリンクが入る場合</a>
                利用規約の本文が入ります利用規約の本文が入ります利用規約の本文が入ります
              </p>
            </div>
          </div>
        </div>
      </div>
    </main>
    <AppFooter />
  </div>
</template>

<script>
export default {
  name: "terms",
  mounted() {
    // ログイン後の状態を確認するためベタ書きでログイン済みにする
    this.$store.commit("auth/set", true);
  },
};
</script>
